<template>
  <div class="covid">
    <div v-if="newLast">
      <h1>
        COVID-19 <code>{{ newLast.nuovi_positivi }}</code>
      </h1>
      <div>Data: {{ newLast.data }}</div>
      <div>Stato: {{ newLast.stato }}</div>
      <div>Ricoverati Con Sintomi: {{ newLast.ricoverati_con_sintomi }}</div>
      <div>Terapia Intensiva: {{ newLast.terapia_intensiva }}</div>
      <div>Totale Ospedalizzati: {{ newLast.totale_ospedalizzati }}</div>
      <div>Isolamento Domiciliare: {{ newLast.isolamento_domiciliare }}</div>
      <div>Totale Positivi: {{ newLast.totale_positivi }}</div>
      <div>
        Variazione Totale Positivi: {{ newLast.variazione_totale_positivi }}
      </div>
      <div>Nuovi Positivi: {{ newLast.nuovi_positivi }}</div>
      <div>Dimessi Guariti: {{ newLast.dimessi_guariti }}</div>
      <div>Deceduti: {{ newLast.deceduti }}</div>
      <div>
        Casi Da Sospetto Diagnostico: {{ newLast.casi_da_sospetto_diagnostico }}
      </div>
      <div>Casi Da Screening: {{ newLast.casi_da_screening }}</div>
      <div>Totale Casi: {{ newLast.totale_casi }}</div>
      <div>Tamponi: {{ newLast.tamponi }}</div>
      <div>Casi Testati: {{ newLast.casi_testati }}</div>
      <div>Note: {{ newLast.note }}</div>
    </div>
    <div v-if="!newLast">
      Loading Covid Data ...
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Covid",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["getStatistic"])
  },
  computed: mapGetters(["newLast"]),
  created() {
    this.getStatistic();
  }
};
</script>

<style scoped>
.amount-input {
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  border: 4px solid cornsilk;
  border-radius: 25px;
  outline-style: none;
  background-color: #f15946;
}
.covid {
  font-size: 18px;
  text-align: left;
  padding-top: 8px;
}
code {
  border: 4px solid cornsilk;
  font-size: 36px;
  color: #f15946;
  padding: 5px;
}
</style>
